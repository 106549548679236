import CookiesConsentContext from '@/contexts/CookiesConsentContext';
import LanguageContext, {
  LanguageContextKey,
} from '@/lib/kustomcms-sdk/lib/contexts/language';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import { useContext, useEffect, useRef } from 'react';

type Window = typeof window & {
  HiJiffyWidget?: {
    Token?: string;
  };
};

export const ScriptsInjector = () => {
  const { settings } = useContext(SettingsContext);
  const { currentLang } = useContext(LanguageContext);
  const id = settings?.modules?.extraFields?.hotelSmartWidgetsId?.value;
  const domain = settings?.modules?.extraFields?.hotelSmartWidgetsDomain?.value;
  const chatbotUrl = settings?.modules?.extraFields?.chatbotUrl?.value;

  // HiJiffyWidget

  const alreadyInjected = useRef(false);
  const { getConsent, consents } = useContext(CookiesConsentContext);

  const injectScriptHiJiffyWidget = () => {
    const _window = window as Window & { axeptioKustomChoices: any };

    const choices: {
      [key: string]: boolean;
    } = _window.axeptioKustomChoices || {};

    // console.log(
    //   'injectScriptHiJiffyWidget ======>',
    //   !choices.hijiffy,
    //   alreadyInjected.current,
    //   !chatbotUrl,
    // );
    // console.log(choices);

    if (!choices.hijiffy || alreadyInjected.current || !chatbotUrl) return;
    alreadyInjected.current = true;

    _window.HiJiffyWidget = _window.HiJiffyWidget || {};
    _window.HiJiffyWidget.Token = 'PxghI0nHCxcwN1';

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.id = 'hijiffy-kustom';
    script.src = chatbotUrl + '?t=' + Math.floor(new Date().getTime() / 60000);
    document.body.appendChild(script);
  };

  // HSW

  const version = '?v=16';

  const alreadyHswInjected = useRef<string | null>(null);
  const injectScriptHotelSmartWidgets = (
    currentLang: LanguageContextKey,
    id: string,
    domain: string,
  ) => {

    const _window = window as Window & { diadao_custom_offers_auto_open_enabled: any }
    _window.diadao_custom_offers_auto_open_enabled = 'always'

    if (alreadyHswInjected.current === currentLang) return;
    alreadyHswInjected.current = currentLang;

    const lang = currentLang === 'fr' ? '' : `-${currentLang}`;
    const currentScript = document.getElementById('diadao-hsw-dist-script-js');
    const currentStyle = document.getElementById('diadao-hsw-dist-style-css');
    const currentHtml = document.getElementById('diadao-hsw-hsp-html');

    if (currentScript) currentScript.remove();
    if (currentStyle) currentStyle.remove();
    if (currentHtml) currentHtml.remove();

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.id = 'diadao-hsw-dist-script-js';
    script.src = `${domain}/hsw-${id}${lang}.js${version}`;

    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = 'diadao-hsw-dist-style-css';
    link.href = `${domain}/hsw-${id}${lang}.css${version}`;
    link.media = 'all';
    link.crossOrigin = 'anonymous';
    document.head.appendChild(link);
  };

  // EFFECT

  useEffect(() => {
    const navigator: any = window?.navigator;

    const _window = window as Window & { axeptioKustomChoices: any };
    const choices: {
      [key: string]: boolean;
    } = _window.axeptioKustomChoices || {};

    // console.log(
    //   'useEffect ======>',
    //   navigator?.userActivation?.hasBeenActive,
    //   choices.hijiffy,
    // );

    if (navigator?.userActivation?.hasBeenActive) {
      injectScriptHiJiffyWidget();
    }
  }, [consents, getConsent]);

  function handleFirstInteraction() {
    window.removeEventListener('click', handleFirstInteraction);
    window.removeEventListener('keydown', handleFirstInteraction);
    window.removeEventListener('mousedown', handleFirstInteraction);
    window.removeEventListener('touchstart', handleFirstInteraction);
    window.removeEventListener('scroll', handleFirstInteraction);
    injectScriptHotelSmartWidgets(currentLang, id, domain);
    injectScriptHiJiffyWidget();
  }

  useEffect(() => {
    if (id && domain) {
      // setTimeout(() => {
      //   injectScriptHotelSmartWidgets(currentLang, id, domain);
      // }, 2000);

      const navigator: any = window?.navigator;
      if (navigator?.userActivation?.hasBeenActive) {
        injectScriptHotelSmartWidgets(currentLang, id, domain);
      } else {
        window.addEventListener('click', handleFirstInteraction);
        window.addEventListener('keydown', handleFirstInteraction);
        window.addEventListener('mousedown', handleFirstInteraction);
        window.addEventListener('touchstart', handleFirstInteraction);
        window.addEventListener('scroll', handleFirstInteraction);
      }
    }

    window.addEventListener('click', (e) => {
      if (e.target instanceof HTMLElement) {
        const isHspClick = e.target?.closest('#smartpreview');
        const isAxeptioClick = e.target?.closest('#axeptio_overlay');
        if (isHspClick || isAxeptioClick) {
          e.stopPropagation();
        } else {
          window.dispatchEvent(new CustomEvent('smartpreviewPanelClose'));
        }
      }
    });
  }, [currentLang]);

  return null;
};
